* {
  box-sizing: border-box;
}

.location-inner-container {
  display: flex;
  flex-direction: column;
  /*justify-content: space-between;*/
  width: 100%;
  height: 100%;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.location-container {
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  width: 366px;
  border-right: 2px solid #b0d1e7;
  box-shadow: -10px 1px 24px 8px #003a62;
  z-index: 30;
  position: absolute;
  background: #fff;
  height: 100%;
  bottom: 0;
}

.storeImage-container {
  min-height: 165px;
  overflow: hidden;
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.storeImage-container > img {
  margin: 0 auto;
}

.storeImage {
  --width: calc((100%));
  width: calc(var(--width) - 50px);
  border-radius: 10px;
  z-index: 4;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
}

.location-title {
  color: #3d3d3d;
  font-weight: 700;
  font-size: 1.5em;
  text-align: left;
}

.click-to-copy {
  font-size: 1em;
  font-weight: 400;
  line-height: 24px;
  color: #4b5154;
  border: 1px solid #b0d1e7;
  border-radius: 4px;
  padding: 0px 20px 12px 20px;
  text-align: left;
  margin-top: 25px;
}

.click-to-copy p {
  max-width: 390px;
}

.click-to-copy-icon-text {
  display: flex;
  align-items: center;
  margin: -17px 0px 0px -35px;
  font-size: 0.9em;
}

.click-to-copy-icon-text img {
  width: 30px;
}

.click-to-copy-icon-text p {
  font-weight: 400;
  font-size: 1em;
  line-height: 24px;
  background: white;
  padding: 0px 5px;
  margin: 0px;
  margin-left: -4px;
}

.open-now {
  display: flex;
  align-items: center;
  font-size: 1em;
  line-height: 20px;
  color: #2e2e2e;
  justify-content: space-between;
  width: 100%;
}

.open-now img {
  max-width: 20px;
  padding-right: 5px;
  position: relative;
  top: 3px;
}

.isOpen {
  color: #229a79;
}

.isClosed {
  color: #db1f63;
  line-height: normal;
}

.schedule {
  border: 1px solid #b0d1e7;
  background: #e6f0f7;
  border-radius: 10px;
  color: #4f4f4f;
}

.schedule ul {
  list-style: none;
  padding: 17px;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.schedule ul li {
  display: flex;
  justify-content: space-between;
  font-size: 0.8em;
}

.schedule ul li p {
  margin: 0;
}

.working-hours {
  display: flex;
  justify-content: flex-end;
  text-align: justify;
}

.get-directions {
  margin-top: 30px;
  gap: 10px;
  height: 20px;
  margin-bottom: 15px;
}

.get-directions img,
.report-issue img {
  width: 24px;
}

.get-directions p {
  font-size: 1.1em;
  line-height: 24px;
  color: #004b7f;
}

.report-issue {
  gap: 10px;
  height: 20px;
}

.get-directions:hover,
.report-issue:hover,
.click-to-copy:hover {
  cursor: pointer;
}

.close-button {
  width: 30px;
  position: absolute;
  top: 30px;
  right: 40px;
}

.arrow_down {
  transform: rotate(180deg);
}

.rotateVertically {
  transform: rotate(360deg);
}

.mobile_open_now {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.open-now > div {
  display: flex;
  align-items: baseline;
}

.open-now > div > p {
  display: flex;
  align-items: baseline;
}

.reopens {
  line-height: 17px;
  font-size: 1em;
  text-align: left;
}

.hideItem {
  visibility: hidden;
}

/*.working-hours {*/
/*  height: 25px;*/
/*  line-height: 14px;*/
/*}*/
.pac-target-input {
  font-size: 16px;
}

.tap-to-view {
  position: relative;
  right: 17px;
  background: #ffffff8c;
  border-radius: 3px;
  padding: 4px 9px;
  font-size: 10px;
}

.overflow {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #ffffffc2;
  z-index: 3;
}

.consent-buttons-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column-reverse;
}

.get-directions {
  text-decoration: none;
  color: #0069b3;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 500px) {
  .location-container {
    width: 100%;
    height: 100%;
  }

  .location-inner-container {
    max-width: 360px;
    margin: 0 auto;
  }

  .close-button-container,
  .tap-to-view-container {
    position: absolute;
    width: 360px;
    display: flex;
    justify-content: flex-end;
  }

  .close-button {
    width: 30px;
    position: relative;
    top: 10px;
    right: 30px;
  }

  .click-to-copy {
    margin-top: 22px;
    font-size: 1em;
  }

  .click-to-copy-icon-text {
    font-size: 0.75em;
  }

  .open-now {
    font-size: 0.9em;
  }

  .open-now p {
    font-size: 0.875em;
    line-height: 20px;
  }

  .schedule ul li {
    margin: 0;
    font-size: 0.65em;
  }

  .schedule ul {
    padding: 20px;
  }

  .get-directions,
  .report-issue {
    margin-top: 20px;
    font-size: 0.75em;
    line-height: 16px;
  }

  .tap-to-view {
    position: relative;
    right: 30px;
  }

  .storeImage {
    width: 360px;
  }
}

@media screen and (max-width: 400px) {
  .close-button-container {
    position: absolute;
    width: 88%;
    display: flex;
    justify-content: flex-end;
  }

  .tap-to-view-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .tap-to-view {
    position: relative;
    right: 20px;
  }

  .storeImage {
    width: calc(var(--width) - 50px);
  }
}
