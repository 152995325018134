.pac-container {
  box-shadow: unset !important;
  border-top: 1px #e6e6e6 solid;
  border-left: 2px #b0d1e7 solid;
  border-right: 2px #b0d1e7 solid;
  border-bottom: 2px #b0d1e7 solid;
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
  z-index: 1;
}

@media screen and (max-width: 500px) {
  .pac-container {
    width: 80% !important;
  }
}

@media screen and (min-width: 501px) {
  .pac-container {
    width: 40% !important;
  }
}
