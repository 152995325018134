body {
  margin: 0;
  font-family:
    "Cera Pro Regular",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Cera Pro Regular", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}
.App{
  display: flex;
}